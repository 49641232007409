.react-datepicker__triangle {
  display: none;
}
.react-datepicker {
  background-color: $light !important;
  border: none !important;
  padding: 5px;
  border-radius: $border-radius-lg;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .18) !important;
}
.react-datepicker-popper {
  margin: 0 !important;
  padding-top: 4px !important;
}
.react-datepicker__navigation.react-datepicker__navigation--next,
.react-datepicker__navigation.react-datepicker__navigation--previous {
  // background-color: $dark !important;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: $border-radius;
}
.react-datepicker__header {
  border: none;
  text-transform: capitalize !important;
  background-color: $light !important;
  border-bottom: 1px solid $grey-light;
}
.react-datepicker__current-month {
  color: $dark !important;
  margin-bottom: 10px;
}
.react-datepicker__day-name {
  color: $muted !important;
  font-weight: $font-weight-bold;
  // padding-right: 20px;
  // padding-left: 20px;
}
// .react-datepicker__week {
//   padding-right: 20px;
//   padding-left: 20px;
// }
.react-datepicker__day {
  color: $muted !important;
}
.react-datepicker__day,
.react-datepicker__day-name {
  margin-right: 13px;
  margin-left: 13x;
}

.react-datepicker__day.react-datepicker__day--outside-month,
.react-datepicker__day--disabled {
  color: $muted !important;
}

.react-datepicker__day--today {
  background-color: $grey-light !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: $grey-light !important;
}

.react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
  display: none !important;
}
.react-datepicker__time,
.react-datepicker__time-container {
  background-color: $white !important;
}
.react-datepicker__time-list-item {
  color: $muted;
  font-size: 16px !important;
  margin-right: 30px !important;
  padding-bottom: 30px !important;
}
.react-datepicker__day--selected {
  background-color: $primary !important;
  color: $white;
}
.react-datepicker__time-list-item:hover,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  background-color: $grey-light !important;
}
.react-datepicker__time-box,
.react-datepicker__time,
.react-datepicker__time-container {
  width: 150px !important;
}
