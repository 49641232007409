// New font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Custom Vars
$poppins: "Poppins", serif;
$open-sans: "Open Sans", sans-serif;
$dark: #001a3a;
$light: #ffffff;
$primary: #0b6dff;
$danger: #eb5757;
$dark-x: #191919;
$grey-x: #575757;
$grey: #ecedef;
$grey-xxxx: #f1f2f5;
$grey-light: #e7e7e9;
$grey-final: #fafafb;
$muted: #445b78;

// Styles
body {
  font-family: $poppins !important;
  font-weight: 400;
}

//Buttons
$body-color: $dark;
$btn-padding-y: 17px;
$btn-padding-x: 20px;
$btn-border-radius: 1rem;
$btn-border-radius-sm: 0.625rem;
$input-custom-border-radius: 16px;
$btn-focus-box-shadow: none !important;
$btn-active-box-shadow: none !important;
$form-feedback-invalid-color: $grey-x;
$form-feedback-valid-color: $grey-x;
// $btn-font-weight: ;

// Rounded
$border-radius-lg: 1rem;
$border-radius-md: 0.5rem;
$border-radius: 1rem;
$modal-content-bg: $light;
$modal-header-border-color: none;
$modal-header-padding-top: 30px;
$modal-transition: transform 0.1s ease-out;
// $border-radius-sm

// Media queries
$sm: 576px;
$md: 768px;
$lg: 991px;
$xl: 1200px;
$xxl: 1400px;

// Modal
$modal-md: 480px;

$font-family-sans-serif: "Poppins", "Poppins", system-ui, -apple-system,
  "Segoe UI", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: "Poppins", "Poppins", SFMono-Regular, Menlo, Monaco,
  Consolas, "Liberation Mono", "Courier New", monospace;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 1rem; // 16px
$font-size-sm: $font-size-base * 0.85; //12px
$font-size-lg: $font-size-base * 1;

$small-font-size: 12px;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 4.5; //72px
$h2-font-size: $font-size-base * 3.5; // DON'T USE
$h3-font-size: $font-size-base * 2.5; //40px
$h4-font-size: $font-size-base * 1.9; //30px
$h5-font-size: $font-size-base * 1.25; // DON'T USE
$h6-font-size: $font-size-base;

$input-bg: $dark-x;
$input-border-color: $dark-x;
$input-border-radius: $input-custom-border-radius;
$input-border-radius-sm: $input-custom-border-radius;
$input-border-radius-lg: $input-custom-border-radius;
$input-box-shadow: none;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-color: $dark;
$input-focus-border-color: none;
$input-placeholder-color: $muted;

$input-padding-y-lg: 17px;
$form-select-border-radius: $input-custom-border-radius;
$form-select-padding-y-lg: 17px;
$form-select-color: $muted;
$form-select-indicator-color: $muted;
$form-select-bg-size: 20px 20px;
$form-select-indicator: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 8.625L12 15.375L18.75 8.625" stroke="#CCCCCC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
);

$dropdown-dark-bg: $light;
$dropdown-border-radius: $border-radius-lg;
$dropdown-divider-bg: $grey-x;

$form-check-input-bg: $grey-xxxx;
$form-check-input-border: 2px solid $muted;
$form-check-input-checked-color: $primary;
$form-check-input-checked-bg-color: $grey;

$list-group-bg: $grey;
$list-group-border-color: $grey-x;
$list-group-color: $light;
$list-group-item-padding-y: $spacer * 0.5;
$list-group-item-padding-x: 0;


$nav-tabs-border-color:             none;
$nav-link-font-size:                14px;
// $nav-tabs-border-width:             $border-width;
$nav-tabs-border-radius:            $border-radius-md;
$nav-link-hover-color:              $dark;

// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color:        $dark;
$nav-link-color: $muted;
$nav-tabs-link-active-bg:           $grey;
// $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

// $form-check-input-border-radius:          .25em;
// $form-check-radio-border-radius:          50%;
// $form-check-input-focus-border:           $input-focus-border-color;
// $form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow;
// Boostrap import
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./avatar";
@import "./react-calendar";
// TOASTIFY
@import "../../node_modules/react-toastify/scss/main.scss";
@import "./toastify-custom.scss";

:root {
  --toastify-color-dark: #191919;
}

.bg-grey-final {
  background-color: $grey-final !important;
}
.bg-light {
  background-color: $grey !important;
}

.text-muted {
  color: $muted !important;
}

.text-dark {
  color: $dark !important;
}

.small-sample-image {
  max-width: 250px;
  max-height: 250px;
}

.pointer {
  cursor: pointer;
}

// .avatar {
//   height: 35px;
// }

// .avatar-img {
//   height: 120px;
//   width: 120px;
//   background-size: cover;
//   background-position: center;
// }
.divider {
  border-bottom: 1px solid $grey-xxxx;
}

.border-bottom {
  border-bottom: 1px solid $grey-light !important;
}

.border-bottom-x {
  box-shadow: inset 0px -1px 0px #f3f4f4;;
}

.simple-linear {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
}

//SCROLLBAR
::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $muted;
  border-radius: 1rem;
  width: 6rem;
}
.road-map {
  ::-webkit-scrollbar {
    width: 6px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: $grey;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 5rem;
    width: 6rem;
  }
}

.navbar {
  box-shadow: inset 0px -1px 0px #f3f4f4;
  .navbar-toggler {
    border: 0;
    &:focus {
      color: transparent;
    }
  }
  
}

.navbar-icon {
  width: 38px;
  height: 38px;
}

.mobile-menu {
  padding: 1rem !important;
  .menu-item {
    font-size: 1.5rem;
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 1.5rem;
  }
}

.lead { font-size: 1.5rem; }

// footer {
//   background-color: $grey-final;
// }

.navbar-toggler-icon {
  // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.navbar-toggler:focus {
  border: none !important;
  color: $dark-x;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $grey;
  opacity: 0.5;
}

.form-control-lg {
  &:focus {
    color: $dark !important;
  }
}

.w-min-content {
  width: min-content;
}

.dropdown-divider {
  margin-right: 15px;
  margin-left: 15px;
}

.hide-menu {
  background: transparent;
  border: none;
}

.markdowned {
  img {
    max-width: 100%;
    margin: $spacer 0;
    border-radius: $border-radius-lg;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.not-allowed {
  cursor: not-allowed;
}

.dropdown-header {
  font-size: inherit !important;
}

.badge {
  font-weight: inherit;
  font-size: 14px;
}

.blur {
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}

.terms-md {
  strong {
    color: $dark;
  }
}

.btn-dark-x {
  background-color: $dark-x;
}

.btn-secondary {
  background-color: $grey;
  color: $dark;
  border: 1px solid $grey;
  &:hover,
  &:active {
    background-color: darken($color: $grey, $amount: 5);
    color: $dark;
    border: 1px solid darken($color: $grey, $amount: 5);
  }
  &:focus {
    background-color: $grey;
    color: $dark;
    border: 1px solid $grey;
  }
  &:disabled {
    background-color: darken($color: $grey, $amount: 5);
    color: $dark;
    border: 1px solid darken($color: $grey, $amount: 5);
  }
}

.btn-outline-secondary {
  color: $dark;
  border: 1px solid $grey;
  &:hover {
    background-color: $light;
    border: 1px solid $grey;
    color: $dark;
  }
}

.btn-white {
  background-color: $light;
}

// .history-list-item {
//   position: relative;
//   display: block;
//   margin-bottom: calc($spacer / 4);
//   color: $muted;
// }

// .history-list-item::before {
//   content: "\25AA";
//   font-family: small;
//   position: absolute;
//   top: -1px;
//   left: -21px;
//   color: $muted;
//   -webkit-user-select: none;
//   user-select: none;
// }
// .history-list-item:not(:last-child)::after {
//   content: "";
//   position: absolute;
//   left: -1rem;
//   top: 10px;
//   display: block;
//   width: 1px;
//   height: 110%;
//   background-color: $muted;
// }

.btn {
  border-radius: $btn-border-radius;
  font-weight: $font-weight-medium;
  font-size: 1rem;
  line-height: 1.4;
}
.btn-sm {
  font-size: 0.875rem;
  padding: 0.75rem 1.25rem;
  border-radius: $btn-border-radius-sm;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  padding: 5rem 0;
}

.form-control {
  background-color: $grey !important;
  color: $dark;
  border: 0;
  font-size: 0.875rem;
  padding: 0.75rem 1.25rem;
  border-radius: 0.625rem;
}

.form-control-sm {
  border-radius: $border-radius-md;
}

.form-select {
  background-color: $grey;
  border-radius: $btn-border-radius-sm;
  color: $dark;
  border: 0;
}

.modal-content {
  border: none;
  .modal-body {
    padding: 0 2rem 2rem 2rem;
  }
}

small {
  font-size: 14px;
  font-weight: $font-weight-normal;
}

.dropdown-menu {
  width: 240px;
  padding: 1.5rem;
  .dropdown-item {
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    &:active {
      background-color: $grey-final;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .dropdown-item-text {
    padding: 0 0 .5rem 0;
  }
}

.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  .card {
    max-width: 400px;
  }
}

.gx-5 {
  --bs-gutter-x: 2.25rem !important;
}

.form-switch {
  input {
    width: 48px !important;
    height: 24px;
    border: 0;
  }
}


@media (max-width: $sm) {
  .btn {
    width: 100%;
  }
  .modal-sm-fullscreen{
   .modal-dialog {
     margin: 0 !important;
     padding: 0!important;
     .modal-content {
       min-height: 100vh;
       border-radius: 0;
     }
   }
  }

  .mx-sm-2-auto{
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

// @media (min-width: $sm) {
//   // .modal-dialog {
//   //   max-width: 480px;
//   // }
// }

.collapse {
  .navbar-nav {
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.zoom {
  transition: transform 0.3s;
}

.zoom-hover {
  transform: scale(0.9);
}

.form-switch {
  .form-check-input {
    &:checked {
      background-color: $primary !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid $grey-xxxx;
  .nav-link {
    background-color: transparent;
    border: 0;
    padding: 1rem;
    font-size: 1rem;
    &.active {
      border: 0;
      border-bottom: 3px solid $primary;
      background-color: transparent;
      font-weight: $font-weight-bold;
    }
  }
}


.icon-svg {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.icon-svg:hover {
  filter: brightness(70%) ;//invert(17%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}
.icon-discord {
  background-image: url(../assets/discord.svg);
}
.icon-linkedin {
  background-image:url(../assets/linkedin.svg)
}
.icon-facebook {
  background-image:url(../assets/facebook.svg)
}
.icon-instagram {
  background-image:url(../assets/instagram.svg)
}
.icon-discord {
  background-image:url(../assets/discord.svg)
}
.icon-reddit {
  background-image:url(../assets/reddit.svg)
}
.icon-twitter {
  background-image:url(../assets/twitter.svg)
}
.icon-medium {
  background-image:url(../assets/medium.svg)
}
.icon-telegram {
  background-image:url(../assets/telegram.svg)
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0;
  margin-right: .5rem;
  border: 1px solid $muted;
  &:checked {
    border: 1px solid $muted;
  }
}

.zi-1055{
  z-index:1055!important;
}

div.hoverable:hover{
  background-color: $grey-light;
}

.react-select__control{
  background-color: $light;
  border: 1px solid $grey-light;
  border-color: $grey-light;
  padding:10px;
  border-radius: $btn-border-radius-sm!important;
  font-weight: $font-weight-bold;
  &:hover, &:active, &:focus {
    border-color: $grey !important;
    box-shadow: none;
  }
  .react-select__indicator-separator {
    background-color: $grey-light;
  }
}

.react-select__menu {
  font-size: 14px;
  font-weight: $font-weight-bold;
}
.react-select__placeholder{
  color: $muted!important;
}

.dropdown-menu-dark {
  .dropdown-item {
    &:hover {
      background-color: $grey;
      border-radius: .5rem;
    }
  }
}

.custom-header-modal{
  padding-bottom:2rem!important;
}

.modal-header{
  padding-top:2rem;
  padding-right: 2rem;
  padding-bottom:0px;
  .btn-close{
     background: transparent url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%23aaaaaa' class='icon text-muted'%3E%3Cpath d='M18.75 1.25L1.25 18.75M18.75 18.75L1.25 1.25L18.75 18.75Z' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E") center/1em auto no-repeat;
  }
}

.ripple-button {
  border: none;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.ripple-button > .ripple {
  width: 60px;
  height: 60px;
  position: absolute;
  background: $grey;
  display: block;
  content: "";
  // border-radius: 9999px;
  opacity: 1;
  animation: 1.2s ease 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}

.ripple-button > .content {
  position: relative;
  z-index: 2;
}
.form-control.is-invalid:focus{
  box-shadow: none;
}
.form-control.is-valid:focus{
  box-shadow: none;
}

.border-grey-light{
  border-color: rgb(224, 224, 224);
}

.loading {
  position: relative;
  background: #eeeeee;
  overflow: hidden;
}
.loading:after {
  overflow: hidden;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-200px);
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  animation: loading 0.8s infinite;
}
@keyframes loading {
  100% {
      transform: translateX(100%);
  }
}

.shadowed{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px 0px;
}

.shadowed:hover{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px 0px;
  transform: translateY(-2px);
}
.pointer-zoom-in {
  cursor: zoom-in;
}
.pointer-zoom-out {
  cursor: zoom-out;
}
.cursor-default {
  cursor: default!important;
}

.border-dashed-muted{
  border: 2px dashed $grey-final;
}

.bg-dark-x{
  background-color: black;
  opacity: 0.8!important;
}